.product-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    /* border: 1px solid #fde4e4; */
    text-align: center;
  
    cursor: pointer;
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    background: linear-gradient(to bottom, #f1f2f3 25px, #303031 25px);
    color: #ffffff;
    overflow: hidden;
  }
  .prodUct-img {
    background: #f1f2f3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 216px;
    border-radius: 0 0 0 60px;
    margin: -1px;
    /* padding: 25px; */
    width: 100%;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  }
  .product-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
    /* border-radius: 10px; */
    border-radius: 0 0 0 55px;
  }
  /* .product-image:hover{
      transform: scale(1.1);
  } */
  .product-info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    /* background-color: yellow; */
    width: 100%;
    padding: 10px;
  }
  
  .product-info-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
    font-size: 16px;
    /* font-family: "Dancing Script", cursive; */
    font-weight: 600;
  }

  .modal-container {
    display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-content: stretch;
  }
  .modal-image {
    width: 200px;
    height: 200px;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    object-fit: contain;
    border-radius: 15px;
    margin-right: 20px;
  }
  .modal-info-header {
    font-weight: 700;
    font-size: 18px;
  }
  .modal-info-price {
    align-self: flex-end;
    /* font-family: "Dancing Script", cursive; */
    font-weight: 600;
    color: red;
    font-size: 22px;
    margin-right: 10px;
    margin: 0px 10px 0px 0px ;
  }
  .stick {
    margin-top: -10px;
  }


.header {
  background-color: #fff;
  -webkit-box-shadow: 0px 1px 9px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 1px 9px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 1px 9px 0px rgba(0,0,0,0.75);
  margin-bottom: 10px;
  width: 100%;
}