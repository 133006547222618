.App {
  background-color: #fff;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  height: 100%;

  margin: 0px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
}

.header {
  background-color: #fff;
  -webkit-box-shadow: 0px 1px 9px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 1px 9px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 1px 9px 0px rgba(0,0,0,0.75);
  margin-bottom: 10px;
  width: 100%;
}

