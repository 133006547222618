.backtotop {
    position: fixed;
    top: 1px;
    left: 5px;
  }

  .backtotop i {
    color: #555555;
    font-size: 40px;
    transition: color 0.6s;
  }