
.firstContainer {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translate3d(0,0,0);
    background-color: #fff;

}
.centered {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  padding-top: 25%;
  width: 100%;
}
.logo {
  width: 180px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 3px #5f5f5f, 0px 0px 0px 5px #ecf0f3, 8px 8px 15px #a7aaa7,
    -8px -8px 15px #fff;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #fff;
}

.menu-buton {
  text-decoration: none;
  color: #252525;
  font-size: 28px;
  font-weight: 600;
  /* font-family: 'Courier New', Courier, monospace; */
  /* font-family: "Dancing Script", cursive; */
}

.bottom-left {
  position: absolute;
  bottom: 0;
  /* left: 16px; */
  /* background: rgb(238, 234, 234); 
    background: rgba(223, 217, 217, 0.5);  */

  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

.mobrecom {
  text-decoration: none;
  color: grey;
}

.menu-button2{
    display: flex;
    flex-direction: column;
    align-items: center;
}