.categroy-box {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
border-radius: 15px;
  /* margin: 0 auto; */

}
.colom{
  padding: 4px !important;

}
.categroy-box .content {
  position: absolute; /* Position the background text */
  bottom: 0; /* At the bottom. Use top:0 to append it to the top */
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1; /* Grey text */
  width: 100%; /* Full width */
  padding: 5px; /* Some padding */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.category-image {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  /* border-radius: 1rem; */
}
.category-name {
  /* display: block; */
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 600;
  /* font-family: "Dancing Script", cursive; */
}

@media only screen and (max-width: 460px) {
  .categroy-box .content{
    height: 13%;
    padding: 0px;
  }
  .category-name {
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    text-align: center;
  } 
}
