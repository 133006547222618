.loading {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  height: 100vh;

  width: 100%;
}
.logo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 3px #5f5f5f, 0px 0px 0px 5px #ecf0f3, 8px 8px 15px #a7aaa7,
    -8px -8px 15px #fff;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  object-fit: fill;
}

.loading-container {
  background-color: #fff;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  margin: 0px;
  -webkit-box-shadow: 0px 0px 29px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 29px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 29px 0px rgba(0,0,0,0.75);
}